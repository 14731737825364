import dynamic from 'next/dynamic';
import { ShippingDimensionBlockProps } from './ShippingDimensionBlock';
import { ShippingDimensionSetProps } from './ShippingDimensionSet';

export type { ShippingDimensionBlockProps } from './ShippingDimensionBlock';
export const ShippingDimensionBlock = dynamic<ShippingDimensionBlockProps>(() =>
  import('./ShippingDimensionBlock').then((module) => module.ShippingDimensionBlock),
);
export const ShippingDimensionSet = dynamic<ShippingDimensionSetProps>(() =>
  import('./ShippingDimensionSet').then((module) => module.ShippingDimensionSet),
);
