import dynamic from 'next/dynamic';
import { JoinedFormFieldProps } from 'types';
import { InteractiveRadioBoxProps } from './InteractiveRadioBox';

export const InteractiveRadioBox = dynamic<InteractiveRadioBoxProps>(() =>
  import('./InteractiveRadioBox').then((module) => module.InteractiveRadioBox),
);
export const ReachRadioBlock = dynamic<JoinedFormFieldProps>(() =>
  import('./ReachRadioBlock').then((module) => module.ReachRadioBlock),
);
export const RadioBox = dynamic<InteractiveRadioBoxProps>(() => import('./RadioBox').then((module) => module.RadioBox));
export const SolveFormRadioBlock = dynamic<JoinedFormFieldProps>(() =>
  import('./SolveFormRadioBlock').then((module) => module.SolveFormRadioBlock),
);
