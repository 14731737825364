import { memo } from 'react';
import { JoinedFormFieldProps } from 'types';
import { validatorMap } from 'consts';
import { handleNestedFieldValidators } from 'utils/validators';
import { formFieldMap } from './FormFieldMap';

export const FieldRenderer = memo(function FieldRenderer(props: JoinedFormFieldProps) {
  const {
    disableAll,
    fields,
    showErrors,
    showParentErrors,
    trackFieldErrors,
    trackFieldErrorsParent,
    type = '',
  } = props;

  const extendFieldProps = (props: JoinedFormFieldProps): JoinedFormFieldProps => {
    return {
      ...props,
      fields: handleNestedFieldValidators(fields),
      isDisabled: disableAll ? true : props.isDisabled,
      showErrors: showParentErrors || showErrors,
      trackFieldErrors: trackFieldErrorsParent || trackFieldErrors,
      validator: typeof props.validator === 'string' ? validatorMap[props.validator] : props.validator,
    };
  };

  return (formFieldMap[type] && formFieldMap[type](extendFieldProps(props))) || formFieldMap.unknown(props);
});
