import { Fragment, ReactElement } from 'react';
import { JoinedFormFieldProps, FormComponentProps, FormCodeVerificationProps, FormNotificationProps } from 'types';
import {
  AvailableTimePicker,
  CalendarClickablePicker,
  CalendarRentRangePicker,
  DateCareerBlock,
  DateTimePicker,
  TimeRangePicker,
  OurDatePicker,
} from 'components/DateTimePickers';
import { MethodSelector } from 'components/MethodSelector';
import { CheckboxSet } from 'components/BooleanSet';
import { BrandSelectionBlock, BrandSelectionBlockProps } from 'components/BrandSelectionBlock';
import { ColorOptionPicker } from 'components/ColorOptionPicker';
import { ColorPicker } from 'components/ColorPicker';
import { CodeVerificationInput } from 'components/CodeVerificationInput';
import { DateRangePicker } from 'components/DateRangePicker';
import { DimensionEditor, DimensionEditorProps } from 'components/DimensionEditor';
import { Dropdown, ApiDropdown } from 'components/Dropdown';
import { DynamicInput, Checkbox, CharityCheckbox, DynamicInputProps } from 'components/Inputs';
import { EnterSelect } from 'components/EnterSelect';
import { ExtendableFields } from 'components/ExtendableFields';
import { ExtendedMultiSelect, MultiSelect, MultiSelectBlock } from 'components/MultiSelect';
import { FileUploader } from 'components/FileUploader';
import { FormSwitch } from 'components/FormSwitch';
import { GmcItemEditor } from 'components/GmcItemEditor';
import { HourRentBlock } from 'components/HourRentBlock';
import { IconCheckBlock } from 'components/IconedCheckBlock';
import { DetailedMediaUploader } from 'components/ImageUploader';
import { ImagesUploader } from 'components/ImageUploader';
import { InteractiveRadioBox, RadioBox, ReachRadioBlock, SolveFormRadioBlock } from '../RadioBox';
import { LocationInput } from 'components/LocationInput';
import { MeetingLocationBlock } from 'components/MeetingLocationBlock';
import { Notification } from 'components/FormNotification';
import { Optionizer, OptionizerProps } from 'components/Optionizer';
import { PhoneInput } from 'components/Inputs';
import { ProductCategoryBlock } from 'components/ProductCategoryBlock';
import { Rating } from 'components/Rating';
import { SchedulePublicationBlock } from 'components/SchedulePublicationBlock';
import { SearchMultiselect } from 'components/SearchMultiselect';
import { ShippingDimensionBlock } from 'components/ShippingDimensionBlock';
import { SizePicker } from 'components/SizePicker';
import { SliderBlock, SliderBlockProps } from 'components/SliderBlock';
import { SocialButton } from 'components/SocialButton';
import { SwitchSet } from 'components/BooleanSet';
import { TextArea } from 'components/TextArea';
import { UserGuideSectionBlock } from 'components/UserGuiseSectionBlock';
import { VideoPhotoBlock } from 'components/ImageUploader';
import { WysiwygEditor } from 'components/WysiwygEditor';
import { SunEditor } from 'components/SunEditor';
import { LocationsBlock, StoresBlock } from 'components/LocationsBlock';
import { GearOptions } from 'components/GearOptions';
import { GearVariants } from 'components/GearVariants';
import { FieldRenderer } from './FieldRenderer';
import { HiddenField } from './HiddenField';
import { UnknownField } from './UnknownField';
import { SvgUploader } from 'components/SvgUploader';
import { StoreAddressSelection } from '../StoreAddressSelection';
import { OpenHours } from '../OpenHours';

import styles from './DynamicForm.module.scss';
import { StartEndTime } from '../StartEndTime';

export const formFieldMap: Record<string, (extendedProps: JoinedFormFieldProps | any) => ReactElement | null> = {
  availableTimePicker: (extendedProps) => {
    return <AvailableTimePicker {...extendedProps} />;
  },
  brandSelectionBlock: (extendedProps: BrandSelectionBlockProps) => {
    return <BrandSelectionBlock {...extendedProps} />;
  },
  calendarClickablePicker: (extendedProps) => {
    return <CalendarClickablePicker {...extendedProps} />;
  },
  calendarRangePicker: (extendedProps) => {
    return <CalendarRentRangePicker {...extendedProps} />;
  },
  checkbox: (extendedProps: JoinedFormFieldProps) => {
    return <Checkbox {...extendedProps} />;
  },
  charityCheckbox: (extendedProps: JoinedFormFieldProps) => {
    return <CharityCheckbox {...extendedProps} />;
  },
  checkboxSet: (extendedProps) => {
    return <CheckboxSet {...extendedProps} />;
  },
  codeVerification: (extendedProps: FormCodeVerificationProps) => {
    return <CodeVerificationInput {...extendedProps} />;
  },
  colorOptionPicker: (extendedProps) => {
    return <ColorOptionPicker {...extendedProps} />;
  },
  colorPicker: (extendedProps) => {
    return <ColorPicker {...extendedProps} />;
  },
  component: ({ child }: FormComponentProps) => {
    return <Fragment>{child}</Fragment>;
  },
  dateCareerBlock: (extendedProps) => {
    return <DateCareerBlock {...extendedProps} />;
  },
  datepicker: (extendedProps) => {
    return <OurDatePicker {...extendedProps} />;
  },
  dateRangePicker: (extendedProps: JoinedFormFieldProps) => {
    return <DateRangePicker {...extendedProps} />;
  },
  dateTimePicker: (extendedProps) => {
    return <DateTimePicker {...extendedProps} />;
  },
  description: ({ value }: JoinedFormFieldProps) => {
    return <p className={styles.formDescription}>{value}</p>;
  },
  descriptionDark: ({ value }: JoinedFormFieldProps) => {
    return <p className={styles.formDescriptionDark}>{value}</p>;
  },
  dimensionEditor: (extendedProps: DimensionEditorProps) => {
    return <DimensionEditor {...extendedProps} />;
  },
  dropdown: (extendedProps: JoinedFormFieldProps) => {
    return <Dropdown {...extendedProps} />;
  },
  apiDropdown: (extendedProps: JoinedFormFieldProps) => {
    return <ApiDropdown {...extendedProps} />;
  },
  email: (extendedProps: JoinedFormFieldProps) => {
    return <DynamicInput {...extendedProps} />;
  },
  enterselect: (extendedProps) => {
    return <EnterSelect {...extendedProps} />;
  },
  extendableFields: (extendedProps) => {
    return <ExtendableFields {...extendedProps} />;
  },
  extendedMultiselect: (extendedProps) => {
    return <ExtendedMultiSelect {...extendedProps} />;
  },
  fileUploader: (extendedProps) => {
    return <FileUploader {...extendedProps} />;
  },
  formSwitch: (extendedProps) => {
    return <FormSwitch {...extendedProps} />;
  },
  gmcItemEditor: (extendedProps) => {
    return <GmcItemEditor {...extendedProps} />;
  },
  guideSectionBlock: (extendedProps) => {
    return <UserGuideSectionBlock {...extendedProps} />;
  },
  hiddenField: (extendedProps) => {
    return <HiddenField {...extendedProps} />;
  },
  hourRentBlock: (extendedProps) => {
    return <HourRentBlock {...extendedProps} />;
  },
  iconCheckBlock: (extendedProps) => {
    return <IconCheckBlock {...extendedProps} />;
  },
  image: (extendedProps) => {
    return <DetailedMediaUploader {...extendedProps} />;
  },
  images: (extendedProps) => {
    return <ImagesUploader {...extendedProps} />;
  },
  iRadioBox: (extendedProps: any) => {
    return <InteractiveRadioBox {...extendedProps} />;
  },
  label: ({ value }: JoinedFormFieldProps) => {
    return <p className={styles.formLabel}>{value}</p>;
  },
  locationBlock: (extendedProps) => {
    return <LocationInput {...extendedProps} />;
  },
  meeting: (extendedProps) => {
    return <MeetingLocationBlock {...extendedProps} />;
  },
  methodSelector: (extendedProps) => {
    return <MethodSelector {...extendedProps} />;
  },
  multiselect: (extendedProps) => {
    return <MultiSelect {...extendedProps} />;
  },
  multiselectBlock: (extendedProps) => {
    return <MultiSelectBlock {...extendedProps} />;
  },
  notification: ({ notifications = {}, fieldName = '' }: FormNotificationProps) => {
    if (notifications[fieldName]) {
      const { mode, header, message, details } = notifications[fieldName];
      return <Notification mode={mode} header={header} message={message} details={details} />;
    }
    return null;
  },
  null: () => null,
  number: (extendedProps: DynamicInputProps) => {
    return <DynamicInput {...extendedProps} />;
  },
  optionizer: (extendedProps: OptionizerProps) => {
    return <Optionizer {...extendedProps} />;
  },
  optionalDivider: () => {
    return <h4>Optional: </h4>;
  },
  password: (extendedProps: JoinedFormFieldProps) => {
    return <DynamicInput {...extendedProps} />;
  },
  phone: (extendedProps: JoinedFormFieldProps) => {
    return <PhoneInput {...extendedProps} />;
  },
  productCategoryBlock: (extendedProps: JoinedFormFieldProps) => {
    return <ProductCategoryBlock {...extendedProps} />;
  },
  radioBox: (extendedProps: any) => {
    return <RadioBox {...extendedProps} />;
  },
  rating: (extendedProps: any) => {
    return <Rating {...extendedProps} />;
  },
  reachRadioBlock: (extendedProps) => {
    return <ReachRadioBlock {...extendedProps} />;
  },
  schedulePublicationBlock: (extendedProps) => {
    return <SchedulePublicationBlock {...extendedProps} />;
  },
  searchMultiselect: (extendedProps) => {
    return <SearchMultiselect {...extendedProps} />;
  },
  secondTitle: ({ value }: JoinedFormFieldProps) => {
    return <h2 style={{ fontSize: '1.5rem' }}>{value}</h2>;
  },
  shippingDimensionBlock: (extendedProps) => {
    return <ShippingDimensionBlock {...extendedProps} />;
  },
  sizePicker: (extendedProps) => {
    return <SizePicker {...extendedProps} />;
  },
  sliderBlock: (extendedProps) => {
    return <SliderBlock {...(extendedProps as SliderBlockProps)} />;
  },
  social: (extendedProps) => {
    return <SocialButton {...extendedProps} />;
  },
  solveFormRadioBlock: (extendedProps) => {
    return <SolveFormRadioBlock {...extendedProps} />;
  },
  subtitle: ({ value }: JoinedFormFieldProps) => {
    return <h3 style={{ marginTop: '32px' }}>{value}</h3>;
  },
  switchSet: (extendedProps) => {
    return <SwitchSet {...extendedProps} />;
  },
  template: (extendedProps: JoinedFormFieldProps) => {
    const {
      gridColumns = 'grid_1_1',
      fields = [],
      onChange,
      trackFieldErrors: trackFieldErrors,
      showErrors,
      notifications,
    } = extendedProps;

    return (
      <div className={styles[gridColumns]}>
        {fields.map((fieldProps, index) => (
          <FieldRenderer
            key={`${fieldProps.fieldName}-${index}`}
            onChange={onChange}
            trackFieldErrors={trackFieldErrors}
            showErrors={showErrors}
            notifications={notifications}
            {...fieldProps}
          />
        ))}
      </div>
    );
  },
  timeRangePicker: (extendedProps) => {
    return <TimeRangePicker {...extendedProps} />;
  },
  title: ({ value }: JoinedFormFieldProps) => {
    return <h2>{value}</h2>;
  },
  text: (extendedProps: JoinedFormFieldProps) => {
    return <DynamicInput {...extendedProps} />;
  },
  textArea: (extendedProps: JoinedFormFieldProps) => {
    return <TextArea {...extendedProps} />;
  },
  videoPhotoBlock: (extendedProps) => {
    return <VideoPhotoBlock {...extendedProps} />;
  },
  wiswigEditor: (extendedProps) => {
    return <WysiwygEditor {...extendedProps} />;
  },
  sunEditor: (extendedProps) => {
    return <SunEditor {...extendedProps} />;
  },
  locations: (extendedProps) => {
    return <LocationsBlock {...extendedProps} />;
  },
  stores: (extendedProps) => {
    return <StoresBlock {...extendedProps} />;
  },
  options: (extendedProps) => {
    return <GearOptions {...extendedProps} />;
  },
  variants: (extendedProps) => {
    return <GearVariants {...extendedProps} />;
  },
  svgUploader: (extendedProps) => {
    return <SvgUploader {...extendedProps} />;
  },
  storeAddress: (extendedProps) => {
    return <StoreAddressSelection {...extendedProps} />;
  },
  openHours: (extendedProps) => {
    return <OpenHours {...extendedProps} />;
  },
  startEndTime: (extendedProps) => {
    return <StartEndTime {...extendedProps} />;
  },
  separator: () => {
    return <div className={styles.separator} />;
  },

  unknown: ({ type, fieldName }) => {
    return <UnknownField type={type} fieldName={fieldName} />;
  },
};
