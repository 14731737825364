import dynamic from 'next/dynamic';
import { LocationBlockProps, StoresBlockProps } from 'types';

export const LocationsBlock = dynamic<LocationBlockProps>(() =>
  import('./LocationsBlock').then((module) => module.LocationsBlock),
);

export const StoresBlock = dynamic<StoresBlockProps>(() =>
  import('./StoresBlock').then((module) => module.StoresBlock),
);
