import { memo, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import Image from 'next/legacy/image';
import { DynamicFormProps } from 'types';
import { isEmptyFiltered } from 'utils/toggleParams';
import { mergeSingleProbablyNestedValueToObject, safePush, setNestedFieldsIfNeeded } from 'utils/formatters';
import { Button } from 'components/Button';
import { FieldRenderer } from './FieldRenderer';

import cx from 'classnames';
import styles from './DynamicForm.module.scss';

export const DynamicForm = memo(function WrappedDynamicForm({
  autoComplete,
  alignButtons = 'alignRight',
  buttonCaption = 'Ok',
  buttonId,
  buttonsShape,
  cancelCaption = 'Cancel',
  children,
  clearDataAtSend,
  dataRef,
  disableAll,
  disableButton,
  errorsRef,
  fields,
  isSlimFooter = true,
  headerIcon,
  liveUpdate,
  marginMode = 'middle',
  notifications = {},
  onSend,
  onCancel,
  optionalButtonProps,
  buttonProps,
  preventUnchangedSend,
  refresh,
  sendButtonType,
  showParentErrors,
  stickButtons,
  stretchForm,
  trackFieldErrorsParent,
  verticalButtons,
  withBackground = false,
  withBackgroundNoAlign = false,
  withFooter,
  underButtonContent,
  onSubmitCapture,
  trackChange,
  simpleDataMerging,
  hiddenScroll,
}: DynamicFormProps) {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [showErrors, setShowErrors] = useState(false);
  const [dataChanged, setDataChanged] = useState<string[]>([]);

  useLayoutEffect(() => {
    setShowErrors(false);
    setDataChanged([]);
    setErrors({});
  }, [fields]);

  useEffect(() => {
    if (isEmptyFiltered(errors)) {
      setShowErrors(false);
    }
    if (errorsRef) {
      errorsRef.current = errors;
    }
  }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = useCallback(
    (fieldName, value) => {
      setFormData((data) => {
        const mergedData = simpleDataMerging
          ? {...data, [fieldName]: value}
          : mergeSingleProbablyNestedValueToObject(data, setNestedFieldsIfNeeded(fieldName, value));
        if (liveUpdate) {
          const isErrors = !isEmptyFiltered(errors);
          if (dataRef) {
            dataRef.current = { ...(dataRef.current || {}), ...mergedData };
          }
          if (!isErrors && onSend) {
            onSend(mergedData, errors);
          }
          if (isErrors && !showErrors) {
            setShowErrors(true);
          }
        }

        trackChange && trackChange(fieldName, value);

        return mergedData;
      });
    },
    [fields], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const trackFieldErrors = useCallback(
    (isValid: boolean, fieldName: string, errorMessage?: string) => {
      if (isValid && !errors[fieldName]) return;
      if (liveUpdate) {
        dataChanged.length>0 && !showErrors && !isValid && setShowErrors(true);
      }
      setErrors((errors) => {
        if (!isValid) {
          return { ...errors, [fieldName]: errorMessage || '' };
        } else {
          errors[fieldName] = 'deleted';
          delete errors[fieldName];
          return errors;
        }
      });
    },
    [errors], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const trackFieldChanges = useCallback((name: string, isChanged: boolean) => {
    if (isChanged) {
      setDataChanged((changedList: string[]) => {
        return safePush(changedList, name);
      });
    } else {
      setDataChanged((changedList: string[]) => {
        return changedList.filter((changedFieldName: string) => changedFieldName !== name);
      });
    }
  }, []);

  const validatedOnSend = (_: any, callback?: (data: any) => void) => {
    const isErrors = !isEmptyFiltered(errors);
    if (!isErrors && (callback || onSend)) {
      callback ? callback(formData) : onSend && onSend(formData);
      clearDataAtSend && setFormData({});
      setShowErrors(false);
    }
    if (isErrors) {
      setShowErrors(true);
    }
  };

  return (
    <form
      className={cx(styles.formWrapper, styles[marginMode], {
        [styles.filledWrapper]: withBackground,
        [styles.filledWrapperNoAlign]: withBackgroundNoAlign,
        [styles.slimFormWithFooter]: withFooter && isSlimFooter,
        [styles.stretchForm]: stretchForm,
        [styles.hiddenScroll]: hiddenScroll,
      })}
      onSubmit={(e) => {
        e.preventDefault()
        return false;
      }}
      onSubmitCapture={onSubmitCapture}
      autoComplete={autoComplete}
    >
      <div className={cx({ [styles.content]: withFooter })}>
        {headerIcon && (
          <Image
            src={headerIcon.path}
            width={headerIcon.width}
            height={headerIcon.height}
            alt={'Icon for dynamic form'}
          />
        )}
        {!!fields?.length &&
          fields.map((field, index) => (
            <FieldRenderer
              key={`${field.fieldName}-${index}`}
              {...field}
              value={formData[field.fieldName] || field.value}
              onChange={onChange}
              errors={errors}
              trackFieldErrors={trackFieldErrors}
              trackFieldErrorsParent={trackFieldErrorsParent}
              showParentErrors={showParentErrors}
              showErrors={showErrors}
              notifications={notifications}
              refresh={refresh}
              trackFieldChanges={trackFieldChanges}
              disableAll={disableAll}
            />
          ))}
      </div>
      {children && children}
      {!liveUpdate && (
        <div
          className={cx(styles[alignButtons], {
            [styles.buttonWrapper]: onSend || onCancel || marginMode !== 'none',
            [styles.stickButtons]: stickButtons,
            [styles.buttonFooter]: withFooter,
            [styles.verticalButtons]: verticalButtons,
          })}
        >
          {!verticalButtons && onCancel && (
            <Button caption={cancelCaption} action={() => onCancel(formData)} shape={buttonsShape} buttonType="white" />
          )}
          {optionalButtonProps && (
            <Button
              caption="Optional"
              shape={buttonsShape}
              buttonType="white"
              action={() =>
                optionalButtonProps?.callback ? validatedOnSend(null, optionalButtonProps?.callback) : null
              }
              {...optionalButtonProps}
            />
          )}
          {onSend && (
            <Button
              id={buttonId}
              caption={buttonCaption}
              action={validatedOnSend}
              shape={buttonsShape}
              buttonType={sendButtonType || 'blue'}
              disabled={(preventUnchangedSend && !dataChanged.length) || disableButton}
              {...buttonProps}
            />
          )}
          {verticalButtons && onCancel && (
            <Button caption={cancelCaption} action={() => onCancel(formData)} shape={buttonsShape} buttonType="white" {...buttonProps} />
          )}
        </div>
      )}
      {underButtonContent && underButtonContent}
    </form>
  );
});
