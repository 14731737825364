import { JoinedFormFieldProps } from '../../types';
import { useEffect } from 'react';

export const HiddenField = ({ value, fieldName = '', onChange }: JoinedFormFieldProps) => {
  useEffect(() => {
    onChange && onChange(fieldName, value);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
