import styles from './UnknownField.module.scss';
import { JoinedFormFieldProps } from 'types';

export const UnknownField = ({ fieldName, type }: JoinedFormFieldProps) => {
  return (
    <div className={styles.container}>
      <p>{`Unknown form field '${fieldName}' type = '${type}'. Please, check DynamicForm -> FieldRenderer component.`}</p>
    </div>
  );
};
