import dynamic from 'next/dynamic';
import { JoinedFormFieldProps } from 'types';
import {SpecificCalendarRangeProps} from "./CalendarRentRangePicker";

export const AvailableTimePicker = dynamic<JoinedFormFieldProps>(() =>
  import('./AvailableTimePicker').then((module) => module.AvailableTimePicker),
);

export const DateTimePicker = dynamic<JoinedFormFieldProps>(() =>
  import('./DateTimePicker').then((module) => module.DateTimePicker),
);
export const TimeRangePicker = dynamic<JoinedFormFieldProps>(() =>
  import('./TimeRangePicker').then((module) => module.TimeRangePicker),
);
export const CalendarClickablePicker = dynamic<JoinedFormFieldProps>(() =>
  import('./CalendarClickablePicker').then((module) => module.CalendarClickablePicker),
);
export const CalendarRentRangePicker = dynamic<JoinedFormFieldProps & SpecificCalendarRangeProps>(() =>
  import('./CalendarRentRangePicker').then((module) => module.CalendarRentRangePicker),
);
export const OurDatePicker = dynamic<JoinedFormFieldProps>(() =>
  import('./DatePicker').then((module) => module.OurDatePicker),
);
export const DateCareerBlock = dynamic<JoinedFormFieldProps>(() =>
  import('./DateCareerBlock').then((module) => module.DateCareerBlock),
);
