import dynamic from 'next/dynamic';
import { JoinedFormFieldProps } from 'types';

export const MultiSelect = dynamic<JoinedFormFieldProps>(() =>
  import('./MultiSelect').then((module) => module.MultiSelect),
);
export const MultiSelectBlock = dynamic<JoinedFormFieldProps>(() =>
  import('./MultiSelectBlock').then((module) => module.MultiSelectBlock),
);
export const ExtendedMultiSelect = dynamic<JoinedFormFieldProps>(() =>
  import('./ExtendedMultiSelect').then((module) => module.ExtendedMultiSelect),
);
