import dynamic from 'next/dynamic';
import { JoinedFormFieldProps } from 'types';
import { ImageUploaderProps } from './DetailedMediaUploader';

export const DetailedMediaUploader = dynamic<ImageUploaderProps>(() =>
  import('./DetailedMediaUploader').then((module) => module.DetailedMediaUploader),
);
export const ImagesUploader = dynamic<JoinedFormFieldProps>(() =>
  import('./ImagesUploader').then((module) => module.ImagesUploader),
);
export const VideoPhotoBlock = dynamic<JoinedFormFieldProps>(() =>
  import('./VideoPhotoBlock').then((module) => module.VideoPhotoBlock),
);
